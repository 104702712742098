import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  ExpandableMenuProps,
  ExpandableMenuStateRefs,
  ExpandableMenuMapperProps,
  ExpandableMenuControllerProps,
} from '../ExpandableMenu.types';

const compController = withCompController<
  ExpandableMenuMapperProps,
  ExpandableMenuControllerProps,
  ExpandableMenuStateRefs,
  ExpandableMenuProps
>(({ stateValues, mapperProps }) => {
  const { currentUrl, reportBi, pageId } = stateValues;
  const {
    compId,
    language,
    pagesMap,
    mainPageId,
    fullNameCompType,
    shouldReportAnalyticsElementsClicks,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnMenuItemClick: ExpandableMenuControllerProps['reportBiOnMenuItemClick'] =
    (event, item) => {
      const { label, link } = item ?? {};

      tryReportAnalyticsClicksBi(reportBi, {
        link,
        language,
        shouldReportAnalyticsElementsClicks,
        elementTitle: label,
        elementType: fullNameCompType,
        elementGroup: AnalyticsClicksGroups.Menu,
        element_id: compId ?? event?.currentTarget.id,
        pagesMetadata: { pagesMap, pageId, mainPageId },
      });
    };

  return {
    ...restMapperProps,
    currentUrl,
    reportBiOnMenuItemClick,
  };
});

export default compController;
